export enum TactiqMessageType {
  getSignedInUser = 'getSignedInUser',
  signIn = 'signIn',
  signOut = 'signOut',
  getSignInToken = 'getSignInToken',
  getTranscript = 'getTranscript',
  getTranscriptSummaries = 'getTranscriptSummaries',
  openOptions = 'openOptions',
  openOptionsPage = 'openOptionsPage',
  getUnsavedTranscripts = 'getUnsavedTranscripts',
  saveManually = 'saveManually',
  deleteManually = 'deleteManually',
  areAllPermissionsGranted = 'areAllPermissionsGranted',
}

export type TactiqMessageGetSignedInUserRequest = {
  type: TactiqMessageType.getSignedInUser;
};

export type TactiqMessageSignInRequest = {
  type: TactiqMessageType.signIn;
  token: string;
};

export type TactiqMessageSignOutRequest = {
  type: TactiqMessageType.signOut;
};

export type TactiqMessageGetSignInTokenRequest = {
  type: TactiqMessageType.getSignInToken;
};

export type TactiqMessageGetTranscriptRequest = {
  type: TactiqMessageType.getTranscript;
  meetingId: string;
};

export type TactiqMessageGetTranscriptSummariesRequest = {
  type: TactiqMessageType.getTranscriptSummaries;
};

export type TactiqMessageOpenOptionsRequest = {
  type: TactiqMessageType.openOptions;
};

export type TactiqMessageOpenOptionsPageRequest = {
  type: TactiqMessageType.openOptionsPage;
  path: string;
};

export type TactiqMessageGetUnsavedTranscriptsRequest = {
  type: TactiqMessageType.getUnsavedTranscripts;
};

export type TactiqMessageSaveManuallyRequest = {
  type: TactiqMessageType.saveManually;
  tid: string;
};

export type TactiqMessageDeleteManuallyRequest = {
  type: TactiqMessageType.deleteManually;
  tid: string;
};

export type TactiqMessageAreAllPermissionsGrantedRequest = {
  type: TactiqMessageType.areAllPermissionsGranted;
};

export type TactiqMessageRequest =
  | TactiqMessageGetSignedInUserRequest
  | TactiqMessageSignInRequest
  | TactiqMessageSignOutRequest
  | TactiqMessageGetSignInTokenRequest
  | TactiqMessageGetTranscriptRequest
  | TactiqMessageGetTranscriptSummariesRequest
  | TactiqMessageOpenOptionsRequest
  | TactiqMessageOpenOptionsPageRequest
  | TactiqMessageGetUnsavedTranscriptsRequest
  | TactiqMessageSaveManuallyRequest
  | TactiqMessageDeleteManuallyRequest
  | TactiqMessageAreAllPermissionsGrantedRequest;

export type TactiqMessageResponse =
  | {
      error: string;
      version?: string;
    }
  | ({
      success?: boolean;
      version?: string;
    } & Record<string, unknown>);

export type TactiqMessageHandlerResult = Record<string, unknown> | void;
