import { getAuth, signInWithCustomToken, signOut, User } from 'firebase/auth';
import { ExtensionIsNotAvailableError, sendMessage } from './extension';
import { store } from '../redux/store';
import { updateExtensionAvailability } from '../redux/modules/global';
import { signOut as signOutRedux } from '../redux/modules/user';
import { logger, TactiqMessageType, SignInResult } from '@tactiq/model';
import { fetchApiV2 } from './api/helpers';
import { baseURL, google_client_id, isProduction } from './firebase/config';
import { SignOutTrigger, trackSignOut } from './analytics';
import { ApolloClientFactory } from '../graphql/client';
import featureFlagService from './feature-flags';

export async function syncLoginWithExtension(
  user: User | null
): Promise<{ loggedIn: boolean; email?: string; redirectTo?: string }> {
  try {
    const signedInUser = await sendMessage<{
      uid: string;
    }>({
      type: TactiqMessageType.getSignedInUser,
    });
    store.dispatch(updateExtensionAvailability(true));

    if (signedInUser?.uid) {
      logger.info('Extension is signed in with uid', signedInUser?.uid);
      if (user?.uid) {
        if (user?.uid === signedInUser.uid) {
          // the same user is logged in to both
          return { loggedIn: true, email: user.email || '' };
        } else {
          // different users are logged in, needs reconciliation
          return { loggedIn: false, redirectTo: '/#/reconcileLogin' };
        }
      } else {
        return logInFromExtension();
      }
    } else {
      if (user?.uid) {
        return logInToExtension();
      } else {
        // nobody is logged in
        return { loggedIn: false };
      }
    }
  } catch (e) {
    if (e instanceof ExtensionIsNotAvailableError) {
      if (typeof chrome !== 'undefined') {
        store.dispatch(updateExtensionAvailability(false));
      }

      return { loggedIn: false };
    } else {
      throw e;
    }
  }
}

export async function logInToExtension(
  maxTries = 10
): Promise<{ loggedIn: boolean }> {
  const token = await fetchCustomToken();

  if (token) {
    await sendMessage(
      {
        type: TactiqMessageType.signIn,
        token,
      },
      maxTries
    );
    return { loggedIn: true };
  } else {
    // TODO: what hapened here???
    return { loggedIn: false };
  }
}

export async function logOutFromExtension(): Promise<void> {
  await sendMessage({
    type: TactiqMessageType.signOut,
  });
}

export async function logInFromExtension(): Promise<{ loggedIn: boolean }> {
  const token = await sendMessage<{
    token: string;
  }>({
    type: TactiqMessageType.getSignInToken,
  });

  if (token?.token) {
    const credential = await signInWithCustomToken(getAuth(), token.token);
    if (credential?.user?.uid) {
      return { loggedIn: true };
    } else {
      return { loggedIn: false };
    }
  } else {
    return { loggedIn: false };
  }
}

export const fetchCustomToken = async (): Promise<string | undefined> => {
  const result = await fetchApiV2<{ token: string }>('/a/user/custom-token');

  return result?.token;
};

const TACTIQ_AUTH_LOGGEDIN_GOOGLE = 'tactiq:auth:loggedin:google';

export function getPreviousGoogleLogin(): string | null {
  return localStorage.getItem(TACTIQ_AUTH_LOGGEDIN_GOOGLE);
}

export function setPreviousGoogleLogin(email?: string): void {
  if (email) {
    localStorage.setItem(TACTIQ_AUTH_LOGGEDIN_GOOGLE, email);
  } else {
    localStorage.removeItem(TACTIQ_AUTH_LOGGEDIN_GOOGLE);
  }
}

export const upsertState = async (
  payload: Record<string, unknown>,
  state?: string
): Promise<string> => {
  const result = await fetchApiV2<{ state: string }>('/u/auth/state', {
    method: 'POST',
    body: JSON.stringify({
      payload,
      state,
    }),
  });

  return result?.state;
};

export function getSignInWithGoogleRedirectURL(
  state: string,
  optionalScopes: string[],
  forceConsent = false
): string {
  const scopes = ['openid', 'email', 'profile'];

  const prevLogin = getPreviousGoogleLogin();
  // either new user or has signed out form the website before
  let prompt = 'select_account';

  if (forceConsent) {
    // required for offline access_type
    prompt = 'consent';
  } else if (prevLogin) {
    // use has already signed in before on this page
    prompt = 'none';
  }

  const config = {
    client_id: google_client_id,
    redirect_uri: `${baseURL}/api/2/u/auth/google`,
    response_type: 'code',
    ...(optionalScopes.length ? { access_type: 'offline' } : {}),
    scope: [...scopes, ...optionalScopes].join(' '),
    state,
    prompt,
    include_granted_scopes: 'true',
  };

  return (
    'https://accounts.google.com/o/oauth2/auth?' +
    new URLSearchParams(Object.entries(config)).toString()
  );
}

export function getSignInWithZoomRedirectURL(state: string): string {
  const config = {
    client_id: isProduction()
      ? 'yBpyMWUORhiY_4tFfCGVFg'
      : '9RASdL7sTMuewMl5aIRk5w',
    redirect_uri: `${baseURL}/api/2/u/integrations/zoom/callback`,
    response_type: 'code',
    state,
  };

  return (
    'https://zoom.us/oauth/authorize?' +
    new URLSearchParams(Object.entries(config)).toString()
  );
}

export async function exchangeCodeForToken(
  code: string
): Promise<SignInResult> {
  return await fetchApiV2<SignInResult>('/u/auth/token', {
    method: 'POST',
    body: JSON.stringify({
      code,
    }),
  });
}

export const sendSignInLinkToEmail = async (
  state?: string
): Promise<{ requireGoogleSignIn?: boolean; team?: boolean }> => {
  return await fetchApiV2<{
    requireGoogleSignIn?: boolean;
    team?: boolean;
  }>('/u/auth/email', {
    method: 'POST',
    body: JSON.stringify({
      state,
    }),
  });
};

export async function forceSignOut(trigger: SignOutTrigger): Promise<void> {
  trackSignOut(trigger);

  featureFlagService.stop();

  await logOutFromExtension().catch((err) => {
    logger.error(err);
  });

  await ApolloClientFactory.exterminate();

  store.dispatch(signOutRedux());

  setPreviousGoogleLogin();
  await signOut(getAuth());
}
