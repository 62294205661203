import { delay, logger } from '@tactiq/model';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import {
  LandingFormExtensionWrapperClasses,
  LandingPageFlavorTextClasses,
} from '../../app/Common/SignInComponents';
import LoadingComponent from '../../app/Landing/Loading/LoadingComponent';
import { Button } from '../../components/buttons';
import {
  getValuefromURL,
  identifyUser,
  setMPDeviceId,
  trackWebEvent,
  trackWebPage,
} from '../../helpers/analytics';
import {
  exchangeCodeForToken,
  getSignInWithZoomRedirectURL,
  logInToExtension,
  setPreviousGoogleLogin,
  upsertState,
} from '../../helpers/authentication';
import featureFlagService from '../../helpers/feature-flags';
import image_google from '../../img/gIcon.svg';
import image_topwave from '../../img/topwave.svg';
import image_zoom from '../../img/zoom-app.svg';
import {
  WelcomeForNewInstalls,
  getMasFromString,
} from './WelcomeForNewInstalls';
import { SignInBlock } from './SignInBlock';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const LandingPageTitleClasses = 'mb-0 font-medium text-2xl text-gray-700';

const LangingMainBlockClasses = `bg-[url(${image_topwave})] bg-[50%_101%] bg-[101%] bg-no-repeat bg-white text-[#384561] flex flex-col justify-center items-center h-screen`;

export const IconGoogleClasses = `bg-[url(${image_google})] bg-transparent bg-[0px_50%] bg-no-repeat w-6 h-6`;

function getFrom() {
  const url = new URL(window.location.href);
  const isWorkplaceAddonRedirect =
    url.searchParams.get('origin') === 'workplace-addon';

  if (isWorkplaceAddonRedirect) return 'workplace-addon';

  return window.location.pathname === '/auth' ||
    window.location.pathname === '/welcome' ||
    window.location.hash.indexOf('#/welcome') === 0
    ? 'extension'
    : 'website';
}

function getRedirect() {
  return (
    window.location.hash.match(/redirect=(?<redirect>[^&]+)/)?.groups
      ?.redirect ?? ''
  );
}

export const SignInPage: React.FC<{
  isWelcomePage?: boolean;
  mas: string | null;
  doNotAutoRedirect?: boolean;
}> = (props) => {
  const [isLoading, setLoading] = useState(true);
  const [oauthState, setOauthState] = useState('');
  const [isSignInInProgress, setIsSignInInProgress] = useState(false);
  const isSignedOut = useSelector((state: RootState) => state.global.signedOut);

  async function processCode(oneTimeCode: string) {
    const signInResult = await exchangeCodeForToken(oneTimeCode);
    const redirect = getRedirect();

    for (let attempt = 0; attempt < 5; attempt++) {
      try {
        const credential = await signInWithCustomToken(
          getAuth(),
          signInResult.token
        );

        if (credential.user.uid) {
          credential.user.email &&
            identifyUser(
              credential.user.uid,
              credential.user.email,
              credential.user.displayName ?? credential.user.email
            );

          trackWebEvent('Signed In - Succeeded', {
            provider: 'custom-token',
            mode: 'api',
          });

          // eslint-disable-next-line no-console
          await logInToExtension(4).catch((e) => console.debug(e)); // it already has 4 tries, let's continue to the main app
        }

        break;
      } catch (e) {
        // https://firebase.google.com/docs/auth/web/custom-auth
        logger.warn('Failed to sign in with custom token.', {
          attempt,
          ...('code' in e && 'message' in e
            ? {
                errorCode: e.code,
                errorMessage: e.message,
              }
            : {}),
        });
        logger.error(e);

        // perhaps the token is not yet usable, let's try again
        await delay(5000);
      }
    }

    window.location.href = redirect ? decodeURIComponent(redirect) : '/';
  }

  const isZoomSignInEnabled = window.location.hash.match(/\?zoom=yeah$/);

  useEffect(() => {
    if (isSignedOut) {
      window.location.reload();
      return;
    }

    const installationId = getValuefromURL('i');
    if (installationId) {
      setMPDeviceId(installationId);
    }

    const oneTimeCode =
      window.location.hash.match(/code=(?<token>[^&]+)/)?.groups?.token;

    const needsClearing = window.location.hash.match(/clear/);

    if (oneTimeCode) {
      // We have been redirected here by the API

      trackWebPage('Signing In Using OTC');

      processCode(oneTimeCode).catch((e) => {
        logger.error(e);
        window.location.href = window.location.href.replace(/#.*/, '');
      });
    } else {
      // This is the initial page load, so need to prepare the correct redirect URL in the API

      if (needsClearing) {
        setPreviousGoogleLogin();
      }

      if (window.location.pathname === '/auth') {
        trackWebPage('Sign In', { from: 'extension' });
      } else if (props.isWelcomePage) {
        // We do not want to track the fact we are hitting the welcome page here. We will track it if the page is not redirected to the web app.
      } else {
        trackWebPage('Sign In', { from: 'website' });
      }

      const initialState: Record<string, unknown> = {
        from: getFrom(),
        redirect: getRedirect(),
      };

      const hasInvitationLink = window.location.hash.match(/\?link=(.*)$/);
      if (hasInvitationLink) {
        logger.info(`You have come from ${hasInvitationLink[1]}`);
        initialState.invitationLink = hasInvitationLink[1];
      }

      const hasTeamInvitationLink =
        window.location.hash.match(/\?teamLink=(.*)$/);
      if (hasTeamInvitationLink) {
        logger.info(`You have come from ${hasTeamInvitationLink[1]}`);
        initialState.teamInvitationLink = hasTeamInvitationLink[1];
      }

      if (props.mas) {
        initialState.meetingAppSelection = getMasFromString(props.mas);
      }

      const init = async () => {
        await featureFlagService.start();

        const state = await upsertState(initialState);
        setOauthState(state);

        setLoading(false);
      };

      init().catch(logger.error);
    }
  }, [isSignedOut, props.isWelcomePage, props.mas]);

  if (isLoading) {
    return <LoadingComponent quiet={true} />;
  }

  if (props.isWelcomePage) {
    return (
      <WelcomeForNewInstalls
        mas={props.mas}
        doNotAutoRedirect={props.doNotAutoRedirect}
        oauthState={oauthState}
      />
    );
  }

  const hrefZoom = getSignInWithZoomRedirectURL(oauthState);

  return (
    <div className={LangingMainBlockClasses}>
      <div className={LandingFormExtensionWrapperClasses}>
        <div className="m-auto flex max-w-md flex-col gap-8">
          {isSignInInProgress ? null : (
            <>
              <p className={LandingPageTitleClasses}>Sign in to Tactiq</p>
              <p className={LandingPageFlavorTextClasses}>
                Log in to save and read transcripts.
              </p>
            </>
          )}

          <SignInBlock
            oauthState={oauthState}
            where={'signin'}
            setIsSignInInProgress={(isIt) => setIsSignInInProgress(isIt)}
          />

          {isZoomSignInEnabled && (
            <div>
              <Button
                variant="outlined"
                size="large"
                href={hrefZoom}
                onClick={() =>
                  trackWebEvent('Click Sign in with Zoom', { mode: 'api' })
                }
                startIcon={
                  <img
                    src={image_zoom}
                    height="26"
                    width="26"
                    alt="Zoom icon"
                  />
                }
              >
                Sign in with Zoom
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
