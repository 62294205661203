import { FeatureFlagServiceBase } from '@tactiq/model';
import { isProduction } from '../firebase/config';

class FeatureFlagService extends FeatureFlagServiceBase {
  isPreviewMeetingsCleanupWarningEnabled(): boolean {
    return this.isEnabled('webapp-enable-preview-meetings-cleanup-warning');
  }

  areActionItemMeetingKitItemsEnabled(): boolean {
    return this.isEnabled('web-enable-meetingkititems-actionitems');
  }

  isMeetingKitPublishingEnabled(): boolean {
    return this.isEnabled('web-enable-meetingkit-publishing');
  }

  isGoogleCalendarWritePermissionsEnabled(): boolean {
    return this.isEnabled('web-enable-google-calendar-write-permissions');
  }

  isReportingEnabled(): boolean {
    return this.isEnabled('web-enable-reporting');
  }

  isSetupPageTypeformEnabled(): boolean {
    return this.isEnabled('web-enable-setuppage-typeform');
  }

  isAiSummaryPreviewExperimentEnabled(): boolean {
    return this.isEnabled('ai-summary-preview-experiment') ?? false;
  }

  isWorkflowsEnabled(): boolean {
    return this.isEnabled('workflows');
  }
}

const featureFlagService = new FeatureFlagService({
  appName: 'tactiq-webapp',
  environment: isProduction() ? 'production' : 'development',
});

export default featureFlagService;
