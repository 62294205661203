/* eslint-disable promise/avoid-new */
/* global chrome */

import {
  logger,
  TactiqMessageRequest,
  TactiqMessageResponse,
} from '@tactiq/model';
import { isProduction } from '../firebase/config';

function delay(milliseconds: number) {
  return new Promise(function (resolve) {
    setTimeout(resolve, milliseconds);
  });
}

export function isEdge(): boolean {
  return !!window.navigator.userAgentData?.brands.find(
    (x) => x.brand === 'Microsoft Edge'
  );
}

async function sendMessageToExtension<T>(
  payload: unknown,
  extensionId: string,
  retrying = false
): Promise<T> {
  return new Promise((resolve, reject) => {
    chrome.runtime.sendMessage(extensionId, payload, function (response) {
      const runtimeError = chrome.runtime.lastError?.message;

      if (
        !retrying &&
        typeof response === 'undefined' &&
        runtimeError?.includes('Could not establish connection')
      ) {
        delay(1000)
          .then(() => {
            return sendMessageToExtension(payload, extensionId, true);
          })
          .then((retried) => {
            return resolve(retried as T);
          })
          .catch((e) => {
            logger.error(e);
            reject(e);
          });
      } else if (runtimeError) {
        reject(runtimeError);
      } else {
        if (response && response.success) {
          resolve(response as T);
        } else {
          reject(response?.error);
        }
      }
    });
  });
}

async function trySendMessage<T>(
  payload: unknown,
  extensionId: string
): Promise<{
  result: T;
  extensionId: string;
} | null> {
  const result = (await sendMessageToExtension(payload, extensionId)) as T;

  if (chrome?.runtime?.lastError) {
    throw new Error(chrome.runtime.lastError.message);
  }

  return { result, extensionId };
}

let DetectedExtensionID: string;

export class ExtensionIsNotAvailableError extends Error {
  isUnavailable: boolean;

  constructor() {
    super('Extension is not available');
    this.isUnavailable = true;
  }
}

export async function sendMessage<T extends TactiqMessageResponse>(
  payload: TactiqMessageRequest,
  maxTries = 10
): Promise<T | null> {
  if (typeof chrome === 'undefined' || !chrome?.runtime?.sendMessage) {
    throw new ExtensionIsNotAvailableError();
  }

  if (DetectedExtensionID) {
    return sendMessageToExtension<T>(payload, DetectedExtensionID);
  } else {
    let result;
    let lastError;

    for (let i = 1; i <= maxTries; i++) {
      try {
        if (i > 1) {
          await delay(200 * i);
          if (!isProduction()) {
            // eslint-disable-next-line no-console
            console.log('Retrying sendMessage...', payload.type, i);
          }
        }

        if (isEdge()) {
          // try sending to the native Edge version
          try {
            result = await trySendMessage<T>(payload, EXTENSION_ID_EDGE);
          } catch (err) {
            logger.info('Dected Tactiq from Chrome Web Store running in Edge');
            result = null;
          }
          if (!result) {
            // maybe they have our Chrome version installed? Edge supports that
            // send to the Chrome version despite the fact that we are in Edge
            result = await trySendMessage<T>(payload, EXTENSION_ID_CHROME);
          }
        } else {
          result = await trySendMessage<T>(payload, EXTENSION_ID_CHROME);
        }

        if (result) break;
      } catch (e) {
        lastError = e;
      }
    }

    if (result) {
      DetectedExtensionID = result.extensionId;
      return result.result;
    } else {
      if (
        chrome.runtime.lastError?.message?.includes(
          'Could not establish connection.'
        ) ||
        lastError?.message?.includes('Could not establish connection.')
      ) {
        throw new ExtensionIsNotAvailableError();
      } else {
        return null;
      }
    }
  }
}

const EXTENSION_ID_EDGE = 'ldihbakgcndcoojkibjniljbadkanaic';
const EXTENSION_ID_CHROME = 'fggkaccpbmombhnjkjokndojfgagejfb';
